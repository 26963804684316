<template>
  <div class="page-contact-container">
    <div class="contact-body-wrapper">
      <div class="page-title-4-mobile" v-if="$isMobile">contact us</div>
      <div class="contact-body-bone">
        <div class="side-contact-info-container">
          <div class="text-info-container">
            <div class="info-group scroll-up-to-show">
              <div class="info-title">上海 <span>ShangHai</span></div>
              <div class="info-main-body">
                <p class="info-text">上海市宝山区真大路520号</p>
                <p class="info-text">昇park创意园1号楼202室 </p>
                <p class="info-text --contacts">联系人_朱小姐 ms. zhu</p>
                <p class="info-text --email">Email_dia@diash.cn</p>
                <p class="info-text">TEL_(086)021-61313410</p>
              </div>
            </div>
            <div class="info-group scroll-up-to-show">
              <div class="info-title">深圳 <span>ShenZhen</span></div>
              <div class="info-main-body">
                <p class="info-text">深圳市福田保税区市花路8号</p>
                <p class="info-text">和合大厦108室</p>
                <p class="info-text --contacts">联系人_余小姐 ms. yu</p>
                <p class="info-text --email">Email_dia@diasz.cn</p>
                <p class="info-text">TEL_(086)0755-82565580</p>
              </div>
            </div>
            <div class="info-group scroll-up-to-show">
              <div class="info-title">德国 <span>Germany</span></div>
              <div class="info-main-body">
                <p class="info-text">Kreishausstrasse 11 |</p>
                <p class="info-text">32051 Herford |</p>
                <p class="info-text">Germany</p>
                <p class="info-text --email">Email_haverkamp@h-id.com</p>
                <p class="info-text">+49 (0) 5221-102 180</p>
              </div>
            </div>
          </div>
          <div class="qr-container scroll-up-to-show">
            <div class="qr-item">
              <div class="title">Wechat</div>
              <div class="qr-img"><img src="/static/images/qr.png" alt="" /></div>
            </div>
          </div>
        </div>
        <div class="contact-main-info-container">
          <div class="title-container scroll-up-to-show text">contact us</div>
          <div class="bg-container scroll-up-to-show">
            <img src="/static/images/contact-bg.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { preloadImgs } from '@/assets/utils/common';

export default {
  beforeCreate() {
    this.$store.commit('openLoading');
  },
  async created() {
    const imgs = ['/static/images/contact-bg.jpg', '/static/images/qr.jpg'];
    try {
      await preloadImgs(imgs);
    } catch (e) {
      console.error(e);
    }
    this.$store.commit('closeLoading');
  },
};
</script>

<style></style>
